import useAuth from './useAuth';
import { axiosInstance } from '../utils/apiConfig';


const useRefreshToken = () => {
    const { isLoggedIn, setAccessToken, setCSRFToken } = useAuth();

    const refresh = async () => {
        if(!isLoggedIn){
            return
        }
        
        const response = await axiosInstance.post('api/auth/refresh-token/');
        setAccessToken(response.data.access);
        setCSRFToken(response.headers["x-csrftoken"]);

        return { accessToken: response.data.access, csrfToken: response.headers["x-csrftoken"] };
    }

    return refresh;
}

export default useRefreshToken;
