import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Link, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { axiosInstance } from '../../utils/apiConfig';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';
import AlertMessage from '../../components/forms/AlertMessage';
import { EmailValidator, usernameValidator, passwordValidator } from '../../components/forms/validators';


const Register = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const [formValues, setFormValues] = useState({
        email: {
            value: '',
            validator: EmailValidator,
            error: false
        },
        username: {
            value: '',
            validator: usernameValidator,
            error: false
        },
        password: {
            value: '',
            validator: passwordValidator,
            error: false
        },
        password2: {
            value: '',
            error: false
        },
    });
    const [formSent, setFormSent] = useState(false);

    // form change handling
    const handleChange = (e) => {
        const { name, value } = e.target;
        const validator = formValues[name].validator;
        let error = '';

        if (name === 'password2') {
            if (value !== formValues.password.value) {
                error = "Passwords do not match";
            } else {
                error = false;
            }
        } else {
            error = validator(value);
        }

        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error
            }
        });
    }

    // form submit handling
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            setMessage(t('form.error.form'));
            setAlertType('error');
        } else {
            setLoading(true);

            let email = formValues.email.value;
            let username = formValues.username.value;
            let password = formValues.password.value;
            let password2 = formValues.password2.value;

            try {
                await axiosInstance.post('api/auth/register/', JSON.stringify({
                    email,
                    username,
                    password,
                    password2
                }));
                setLoading(false);
                setFormSent(true);
                setMessage(t('form.registrationSucceeded'));
                setAlertType('success');
            } catch (err) {
                setLoading(false);
                setMessage(t('form.registrationFailed'));
                setAlertType('error');
                // TODO: handle errors
                console.error(err);
            }
        }
    }

    const heroTitle = t('form.registerTitle');
    const heroContent = (
        <Typography>
            {t('form.alreadyRegistered')}
            {" "}
            <Link href='/auth/login/' sx={{ color: 'white' }}>
                {t('form.signIn')}
            </Link>
        </Typography>
    );

    const showForm = (
        <Box
            component='form'
            onSubmit={handleSubmit}
            onChange={handleChange}
            noValidate
        >
            <TextField
                name='email'
                type='email'
                label={t('form.email')}
                value={formValues.email.value}
                onChange={handleChange}
                error={!!formValues.email.error}
                helperText={formValues.email.error}
                required
                fullWidth
                margin='dense'
            />
            <TextField
                name='username'
                label={t('form.username')}
                value={formValues.username.value}
                onChange={handleChange}
                error={!!formValues.username.error}
                helperText={formValues.username.error}
                required
                fullWidth
                margin='dense'
            />
            <TextField
                name='password'
                type='password'
                label={t('form.password')}
                value={formValues.password.value}
                onChange={handleChange}
                error={!!formValues.password.error}
                helperText={formValues.password.error}
                required
                fullWidth
                margin='dense'
            />
            <TextField
                name='password2'
                type='password'
                label={t('form.passwordConfirm')}
                value={formValues.password2.value}
                onChange={handleChange}
                error={!!formValues.password2.error}
                helperText={formValues.password2.error}
                required
                fullWidth
                margin='dense'
            />
            <Button
                type='submit'
                variant='contained'
                disabled={loading}
                fullWidth
                sx={{ mt: '0.5rem' }}
            >
                {t('form.buttonRegister')}
            </Button>
        </Box>
    );

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12, sm: 10, md: 8 }} offset={{ xs: 0, sm: 1, md: 2 }}>
                {!formSent && showForm}
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    )
}

export default Register;
