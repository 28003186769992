import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchData } from '../actions';

import PanelList from '../components/PanelList';
import Hero from '../components/layout/Hero';
import Main from '../components/layout/Main';


const Panels = () => {
    const { t } = useTranslation();
    const [allPanels, setAllPanels] = useState(null);

    useEffect(() => {
        fetchData('api/panel/')
            .then(data => setAllPanels(data))
            .catch(() => { })
    }, []);

    const heroTitle = t('panel.list.title');
    const heroContent = "";

    const mainContent = (
        <PanelList panels={allPanels} />
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default Panels;