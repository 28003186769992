import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/usePrivate';
import useRefreshToken from '../hooks/useRefreshToken';


const PersistLogin = () => {
    const { accessToken, isLoggedIn, setUser, setAccessToken, setCSRFToken, setIsLoggedIn } = useAuth();
    const refresh = useRefreshToken();
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;

        const verifyUser = async () => {
            if (!isLoggedIn) {
                isMounted && setLoading(false);
                return
            }

            try {
                await refresh();
                const { data } = await axiosPrivate.get('api/auth/user/');
                setUser(data);
            } catch (error) {
                // reset login if refresh token is not valid anymore
                if (error.response.data.code === 'token_not_valid') {
                    setAccessToken(null);
                    setCSRFToken(null);
                    setUser({});
                    setIsLoggedIn(false);
                } else {
                    console.log("ERROR in PersistLogin:", error?.response);
                }
            } finally {
                isMounted && setLoading(false);
            }
        }

        !accessToken ? verifyUser() : setLoading(false);

        return () => {
            isMounted = false;
        }
    }, [accessToken, isLoggedIn, setUser, setAccessToken, setCSRFToken, setIsLoggedIn, refresh, axiosPrivate]);


    return (
        loading ? "Loading" : <Outlet />
    )
}

export default PersistLogin;
