import { Box, Icon } from '@mui/material';


const Loading = () => {
    return (
        <Box sx={{ mr: { xs: '0.5rem', md: '2rem' } }}>
            <Icon className='fas fa-spinner' />
        </Box>
    );
}

export default Loading;