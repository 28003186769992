export const BACKEND_MEDIA_URL = process.env.REACT_APP_BACKEND_MEDIA_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;

// The same (in days) than in SIMPLE_JWT django settings
export const ACCESS_TOKEN_LIFETIME = 1;

export const LOGO = PUBLIC_URL + 'assets/images/innext-logo.png';
export const LOGO_FOOTER_EN = PUBLIC_URL + 'assets/images/footer-logot-EN.png';
export const LOGO_FOOTER_FI = PUBLIC_URL + 'assets/images/footer-logot-FI.png';

export const NO_IMAGE = PUBLIC_URL + 'assets/images/noimg.png';