import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { fetchData } from '../actions';

import Hero from '../components/layout/Hero';
import Main from '../components/layout/Main';
import PanelCard from '../components/PanelCard';


const Home = () => {
    const { t } = useTranslation();
    const [openPanels, setOpenPanels] = useState(null);

    useEffect(() => {
        fetchData('api/panel_list/')
            .then(data => setOpenPanels(data))
            .catch(() => { })
    }, []);
    
    const heroTitle = t('home.welcomeTitle');
    const heroContent = t('home.welcomeText');

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant='h2'>
                    {t('home.openPanels')}
                </Typography>
            </Grid>
            {openPanels && (
                <PanelCard panels={openPanels} />
            )}
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main content={mainContent} />
        </>
    );
}

export default Home;