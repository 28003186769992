import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid2';

import { fetchData } from '../actions';
import { DEFAULT_LANGUAGE } from '../constants';
import { useLanguage } from '../context/LanguageContext';

import Hero from '../components/layout/Hero';
import Main from '../components/layout/Main';


const Page = ({ slug }) => {
    const { language } = useLanguage();
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        const pageApiUrl = `api/page/${slug}/`;

        fetchData(pageApiUrl)
            .then(data => setPageData(data))
            .catch(() => { })
    }, [slug]);
    
    let { title, content } = '';

    if (pageData !== null) {
        title = pageData.title[language] ? pageData.title[language] : pageData.title[DEFAULT_LANGUAGE];
        content = pageData.content[language] ? pageData.content[language] : pageData.content[DEFAULT_LANGUAGE];
    }

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={12}>
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={title} />
            <Main content={mainContent} />
        </>
    );
}

export default Page;