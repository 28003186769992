import { PUBLIC_URL, ACCESS_TOKEN_LIFETIME } from '../constants';
import { axiosInstance, axiosPrivateInstance } from '../utils/apiConfig';


export const fetchData = async (endpoint) => {
    try {
        const response = await axiosInstance.get(endpoint);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('ERROR! Server responded with status:', error.response.status);
            console.error('Response data:', error.response.data);
        } else if (error.request) {
            console.error('ERROR! No response received:', error.request);
        } else {
            console.error('ERROR! Error setting up request:', error.message);
        }
        throw error;
    }
};

export const fetchCombinedData = async (endpoints) => {
    try {
        // Create an array of promises for each endpoint
        const requests = endpoints.map((endpoint) => axiosInstance.get(endpoint));
        // Wait for all promises to resolve and extract data from each response
        const responses = await Promise.all(requests);
        const resultData = responses.map((response) => response.data);
        return resultData;
    } catch (error) {
        console.log("ERROR!", error.response);
        throw error;
    }
};

export const fetchPrivateData = async (endpoint) => {
    try {
        const { data } = await axiosPrivateInstance.get(endpoint);
        return data;
    } catch (error) {
        console.log("ERROR!", error.response);
        throw error;
    }
}

export const fetchCombinedPrivateData = async (endpoints) => {
    try {
        // Create an array of promises for each endpoint
        const requests = endpoints.map((endpoint) => axiosPrivateInstance.get(endpoint));
        // Wait for all promises to resolve and extract data from each response
        const responses = await Promise.all(requests);
        const resultData = responses.map((response) => response.data);
        return resultData;
    } catch (error) {
        console.log("ERROR!", error.response);
        throw error;
    }
};

export const getPanelURL = (panelSlug) => {
    return PUBLIC_URL + 'panel/' + panelSlug + '/';
}

export const getSectionURL = (panelSlug, sectionId) => {
    return PUBLIC_URL + 'panel/' + panelSlug + '/' + sectionId + '/';
}

export const getClosureTime = (time) => {
    return new Date(time).getTime() < new Date().getTime();
}

export const getCookieWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();

    // Compare the expiry time of the item with the current time
    // Delete the item from storage if the item is expired
    if (now > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

export const setCookieWithExpiry = (key, value) => {
    const now = new Date().getTime();
    // Reset when storage is more than X days
    const ttl = (24 * 60 * 60 * 1000) * ACCESS_TOKEN_LIFETIME;

    // `item` is an object which contains the original value as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now + ttl,
    }

    localStorage.setItem(key, JSON.stringify(item));
}
