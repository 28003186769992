import { IconButton, Typography } from '@mui/material';


const userMenuStyles = {
    flexDirection: 'column',
    mx: '0.25rem',
    borderRadius: 0,
    color: 'investDarkGrey',
    '&:hover': {
        bgcolor: 'transparent',
        color: 'black',
    },
}

export const UserNavButton = ({ action, icon, label }) => {
    return (
        <IconButton
            onClick={action}
            size='small'
            sx={userMenuStyles}
        >
            {icon}
            <Typography variant='caption' fontSize='0.75rem'>
                {label}
            </Typography>
        </IconButton>
    );
}

export const UserNavLink = ({ action, icon, label }) => {
    return (
        <IconButton
            href={action}
            size='small'
            sx={userMenuStyles}
        >
            {icon}
            <Typography variant='caption' fontSize='0.75rem'>
                {label}
            </Typography>
        </IconButton>
    );
}

/*export const UserNavItem = ({ type, action, icon, label }) => {

    let buttonAction = '';

    if (type === 'button')
        buttonAction = 'onClick=' + {action}
    else
        buttonAction = 'href=' + {action}

    return (
        <IconButton
            href={action}
            size='small'
            classes='fas fa-plus-circle'
            sx={userMenuStyles}
        >
            <FontAwesomeIcon icon={faEllipsisV} />
            <Typography variant='caption' fontSize='0.75rem'>
                {label}
            </Typography>
        </IconButton>
    );
}*/
