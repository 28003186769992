import useAuth from './useAuth';
import useAxiosPrivate from './usePrivate';


const useUser = () => {
    const { isLoggedIn, setUser } = useAuth();
    const axiosPrivateInstance = useAxiosPrivate();

    async function getUser() {
        if (!isLoggedIn) {
            return
        }

        try {
            const { data } = await axiosPrivateInstance.get('api/auth/user/');
            setUser(data);
        } catch (error) {
            console.log("ERROR in useUser", error.response);
        }
    }

    return getUser;
}

export default useUser;
