import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Link, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { axiosInstance } from '../../utils/apiConfig';
import useAuth from '../../hooks/useAuth';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';
import AlertMessage from '../../components/forms/AlertMessage';
import { EmailValidator, loginPasswordValidator } from '../../components/forms/validators';


const Login = () => {
    const { t } = useTranslation();

    const { setAccessToken, setCSRFToken, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const fromLocation = location?.state?.from?.pathname || '/';

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const [formValues, setFormValues] = useState({
        email: {
            value: '',
            validator: EmailValidator,
            error: false
        },
        password: {
            value: '',
            validator: loginPasswordValidator,
            error: false
        }
    });

    // form change handling
    const handleChange = (e) => {
        const { name, value } = e.target;
        const validator = formValues[name].validator;
        let error = validator(value);

        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error
            }
        });
    }

    // form submit handling
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            setMessage(t('form.error.form'));
            setAlertType('error');
        } else {
            setLoading(true);

            let email = formValues.email.value;
            let password = formValues.password.value;

            try {
                const response = await axiosInstance.post('api/auth/login/', JSON.stringify({
                    email,
                    password
                }))

                setAccessToken(response?.data?.access_token)
                setCSRFToken(response.headers["x-csrftoken"])
                setIsLoggedIn(true);
                setLoading(false);
                setMessage(t('form.loginSucceeded'));

                navigate(fromLocation, { replace: true })
            } catch (error) {
                setLoading(false);
                // TODO: handle errors
                setMessage(error.response.data.detail);
                setAlertType('error');
            }
        }
    };

    const heroTitle = t('form.loginTitle');
    const heroContent = (
        <Typography>
            {t('form.noAccount')}
            {" "}
            <Link href='/auth/register/' sx={{ color: 'white' }}>
                {t('form.registerNow')}
            </Link>
        </Typography>
    );

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12, sm: 10, md: 8 }} offset={{ xs: 0, sm: 1, md: 2 }}>
                <Box
                    component='form'
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    noValidate
                >
                    <TextField
                        name='email'
                        type='email'
                        label={t('form.email')}
                        value={formValues.email.value}
                        onChange={handleChange}
                        error={!!formValues.email.error}
                        helperText={formValues.email.error}
                        required
                        fullWidth
                        margin='dense'
                    />
                    <TextField
                        name='password'
                        type='password'
                        label={t('form.password')}
                        value={formValues.password.value}
                        onChange={handleChange}
                        error={!!formValues.password.error}
                        helperText={formValues.password.error}
                        required
                        fullWidth
                        margin='dense'
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={loading}
                        fullWidth
                        sx={{ mt: '0.5rem' }}
                    >
                        {t('form.buttonLogin')}
                    </Button>
                </Box>
                <Box sx={{ padding: '3rem 0 1rem', fontSize: '0.9rem' }}>
                    <Link href='/auth/reset-password/'>
                        {t('form.forgotPassword')}
                    </Link>
                    <br />
                    <Link href='/auth/resend-activation/'>
                        {t('form.resendActivation')}
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default Login;
