import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, Icon, ImageListItem, ImageListItemBar, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { fetchData, getClosureTime } from '../actions';
import { DEFAULT_LANGUAGE } from '../constants';
import { useLanguage } from '../context/LanguageContext';
import FormatTime from '../utils/formatTime';

import AlertMessage from '../components/forms/AlertMessage';
import Hero from '../components/layout/Hero';
import Main from '../components/layout/Main';
import ContactPersons from '../components/panels/ContactPersons';
import ExpandContent from '../components/panels/ExpandContent';
import SectionList from '../components/panels/SectionList';


const Panel = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const { slug } = useParams();

    const [panelData, setPanelData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const [accessCode, setAccessCode] = useState(null);
    const [accessCodeError, setAccessCodeError] = useState(false);
    const [showPanelContent, setShowPanelContent] = useState(false);

    useEffect(() => {
        const panelApiUrl = 'api/panel/' + slug;

        fetchData(panelApiUrl)
            .then(data => setPanelData(data))
            .catch(() => { })
    }, [slug]);

    let { access_code, open_at, close_at, closed, comment_amount, image } = '';
    let { title, abstract, content, caption, alt_text } = '';
    let { contact_persons, sections } = '';

    if (panelData !== null) {
        access_code = panelData.access_code;
        open_at = FormatTime(panelData.open_at, language);
        close_at = FormatTime(panelData.close_at, language);
        closed = getClosureTime(panelData.close_at);
        comment_amount = panelData.n_comments;
        image = panelData.image;

        title = panelData.title[language] ? panelData.title[language] : panelData.title[DEFAULT_LANGUAGE];
        abstract = panelData.abstract[language] ? panelData.abstract[language] : panelData.abstract[DEFAULT_LANGUAGE];
        content = panelData.content[language] ? panelData.content[language] : panelData.content[DEFAULT_LANGUAGE];
        alt_text = panelData.alt_text[language] ? panelData.alt_text[language] : panelData.alt_text[DEFAULT_LANGUAGE];
        caption = panelData.caption[language] ? panelData.caption[language] : panelData.caption[DEFAULT_LANGUAGE];

        contact_persons = panelData.contact_persons;
        if (contact_persons.length === 0)
            contact_persons = '';

        sections = panelData.sections;
        if (sections.length === 0)
            sections = '';
    }

    const handleChange = (e) => {
        setAccessCode(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (e.currentTarget.checkValidity() === false) {
            setAccessCodeError(true);
            setMessage(t('form.error.form'));
            setAlertType('error');
        } else { 
            setLoading(true);
            setAccessCodeError(false);

            if (accessCode === access_code) {
                setLoading(false);
                setShowPanelContent(true);
            } else {
                setLoading(false);
                setAccessCodeError(true);
                setMessage(t('panel.accessCodeError'));
                setAlertType('error');
            }
        }
    }

    const heroTitle = title;
    const heroContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                <Divider variant='middle' sx={{ my: '1rem', bgcolor: 'investTurq' }} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} sx={{ position: 'relative', pl: '3rem', textAlign: 'left', fontSize: '1rem' }}>
                <Icon
                    className='far fa-clock'
                    sx={{ position: 'absolute', left: '1rem', verticalAlign: 'top' }}
                />
                {t('panel.open')} {open_at}<br />
                {closed ? t('panel.closed') : t('panel.closing')} {close_at}
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} sx={{ position: 'relative', pl: '3rem', textAlign: 'left', fontSize: '1rem' }}>
                <Icon
                    className='far fa-comment'
                    sx={{ position: 'absolute', left: '1rem', verticalAlign: 'bottom' }}
                />
                {t('panel.totalComments', { amount: comment_amount })}
            </Grid>
        </Grid>
    );

    const information = (
        <Box sx={{ mx: '2.5rem' }}>
            <Box>
                {abstract}
            </Box>
            {image && (
                <ImageListItem component='div' sx={{ my: '1.25rem' }}>
                    <img
                        src={image}
                        alt={alt_text}
                        loading='lazy'
                    />
                    {caption && <ImageListItemBar title={caption} />}
                </ImageListItem>
            )}
            <Box component='div'
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </Box>
    );

    const panelContent = (
        <>
            <Grid size={{ xs: 12 }}>
                {closed && <AlertMessage message={t('panel.closureInfo')} type='warning' />}
                <ExpandContent buttonText={t('panel.titleInformation')} collapseContent={information} openDefault={true} />
                <Divider variant='middle' sx={{ my: '1rem', bgcolor: '#eee' }} />
                {contact_persons && (
                    <>
                        <ContactPersons persons={contact_persons} />
                        <Divider variant='middle' sx={{ my: '1rem', bgcolor: '#eee' }} />
                    </>
                )}
            </Grid>
            {sections && (
                <SectionList panelSlug={slug} sections={sections} />
            )}
        </>
    );

    const requestAccessCode = !showPanelContent ? (
        <>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12, sm: 10, md: 8 }} offset={{ xs: 0, sm: 1, md: 2 }}>
                <Typography variant='h3'>
                    {t('panel.accessCodeTitle')}
                </Typography>
                <Box component='form' onSubmit={handleSubmit} noValidate>
                    <TextField
                        required
                        label={t('panel.accessCode')}
                        value={accessCode}
                        onChange={handleChange}
                        error={accessCodeError}
                        fullWidth
                        margin='dense'
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={loading}
                        fullWidth
                        sx={{ mt: '0.5rem' }}
                    >
                        {t('form.buttonSubmit')}
                    </Button>
                </Box>
            </Grid>
        </>
    ) : panelContent;

    const mainContent = (
        <Grid container spacing={2}>
            {access_code ? requestAccessCode : panelContent}
        </Grid >
    );


    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );

}

export default Panel;